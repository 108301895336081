export const URL_DASHBOARD = '/admin/dashboard';
// post
export const URL_POST_CREATE = '/admin/post/create-new';
export const URL_POST_LIST = '/admin/post/list';

// category
export const URL_CATEGORY_CREATE = '/admin/category/create-new';
export const URL_CATEGORY_LIST = '/admin/category/list';

// tag
export const URL_TAG_CREATE = '/admin/tag/create-new';
export const URL_TAG_LIST = '/admin/tag/list';

// settings
export const URL_SETTINGS = '/admin/settings';
export const URL_DOCS = '/admin/docs';
