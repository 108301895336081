import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { ContentTypes } from './ContentTypes';

const baseAPI = 'http://localhost:5602';
// const baseAPI = 'https://admin.mina-corner.com';

const contentStates = {
    baseAPI,
    user: {}
};

const ContentStore = createSlice<ContentSpaceDataType, ContentTypes>({
    name: 'content-space',
    initialState: contentStates as ContentSpaceDataType,
    reducers: {
        initStore: () => {
            // state.title = action.payload.title;
        },
        updateUser: (state, action) => {
            state.user = action.payload?.user;
        }
    }
});

export const { initStore, updateUser } = ContentStore.actions;

export default ContentStore.reducer;
