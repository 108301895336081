import {
    URL_CATEGORY_CREATE,
    URL_CATEGORY_LIST,
    URL_DASHBOARD,
    URL_DOCS,
    URL_POST_CREATE,
    URL_POST_LIST,
    URL_SETTINGS
} from '@constants/router-path';
import IconArticle from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/Article';
import IconCategory from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/Category';
import IconDocSend from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/DocSend';
import IconSettings from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/Settings';
import IconTag from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/Tag';
import IconTemplate from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/Template';

export type MenuTypeProps = {
    id: string;
    title?: string;
    type?: 'collapse' | 'item' | 'group';
    url?: string;
    icon?: React.ReactNode;
    breadcrumbs?: boolean;
    children?: MenuTypeProps[];
};

const pages: MenuTypeProps[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: URL_DASHBOARD,
        breadcrumbs: false,
        icon: <IconTemplate />
    },
    {
        id: 'main-group',
        title: 'Main',
        type: 'group',
        children: [
            {
                id: 'post',
                title: 'Posts',
                type: 'collapse',
                icon: <IconArticle />,
                children: [
                    {
                        id: 'create-new',
                        title: 'Create New',
                        type: 'item',
                        url: URL_POST_CREATE,
                        breadcrumbs: false,
                        icon: 'list-style'
                    },
                    {
                        id: 'list',
                        title: 'List',
                        type: 'item',
                        url: URL_POST_LIST,
                        breadcrumbs: false,
                        icon: 'list-style'
                    }
                ]
            },
            {
                id: 'category',
                title: 'Categories',
                type: 'collapse',
                icon: <IconCategory />,
                children: [
                    {
                        id: 'create-new',
                        title: 'Create New',
                        type: 'item',
                        url: URL_CATEGORY_CREATE,
                        breadcrumbs: false,
                        icon: 'list-style'
                    },
                    {
                        id: 'list',
                        title: 'List',
                        type: 'item',
                        url: URL_CATEGORY_LIST,
                        breadcrumbs: false,
                        icon: 'list-style'
                    }
                ]
            },
            {
                id: 'tag',
                title: 'Tags',
                type: 'collapse',
                icon: <IconTag />,
                children: [
                    {
                        id: 'create-new',
                        title: 'Create New',
                        type: 'item',
                        url: URL_CATEGORY_CREATE,
                        breadcrumbs: false,
                        icon: 'list-style'
                    },
                    {
                        id: 'list',
                        title: 'List',
                        type: 'item',
                        url: URL_CATEGORY_LIST,
                        breadcrumbs: false,
                        icon: 'list-style'
                    }
                ]
            }
        ]
    },
    {
        id: 'more-group',
        title: 'More',
        type: 'group',
        children: [
            {
                id: 'settings',
                title: 'Settings',
                type: 'item',
                url: URL_SETTINGS,
                icon: <IconSettings />,
                breadcrumbs: false
            },
            {
                id: 'docs',
                title: 'Document',
                type: 'item',
                url: URL_DOCS,
                icon: <IconDocSend />,
                breadcrumbs: false
            }
        ]
    }
];

export default pages;
