import { updateUser } from '@stores/ContentStore';
import axios from 'axios';
import cx from 'classnames';
import jwtdecode from 'jwt-decode';
import _ from 'lodash';
import Box from 'qnb-ui/src/components/Box';
import Button from 'qnb-ui/src/components/Button';
import Input from 'qnb-ui/src/components/Input';
import { setCookie } from 'qnb-ui/src/helpers/cookie';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Login.module.scss';

function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const baseAPI = useSelector(
        (state: { contentStore: ContentSpaceDataType }) => state.contentStore.baseAPI
    );
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleLogin = () => {
        if (!_.isEmpty(username) && !_.isEmpty(password)) {
            axios
                .post(`${baseAPI}/user/login`, {
                    username,
                    password
                })
                .then(
                    (result: {
                        status?: number;
                        data?: {
                            message?: string;
                            tokens?: { accessToken?: string; refreshToken?: string };
                            permission?: number;
                        };
                    }) => {
                        if (result?.status === 200 && result.data?.tokens?.accessToken) {
                            const user: ContentSpaceDataType['user'] = jwtdecode(
                                result.data?.tokens.accessToken
                            );
                            delete user?.exp;
                            delete user?.iat;
                            dispatch(updateUser({ user }));

                            setCookie('user', result.data?.tokens.accessToken);
                            return navigate('/admin');
                        }
                    }
                )
                .catch(error => console.log(error));
        }
    };

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') handleLogin();
        };

        window.document.addEventListener('keypress', handleKeyPress);

        return () => window.document.removeEventListener('keypress', handleKeyPress);
    });

    return (
        <Box className={cx(styles.root)}>
            <Box direction="vertical" className={styles.container}>
                <h1>Login!</h1>
                <br />
                <Box direction="vertical" gap="12px">
                    <Input
                        value={username}
                        placeholder="username"
                        onChange={value => _.isString(value) && setUsername(value)}
                        onClear={() => setUsername('')}
                    />
                    <Input
                        type="password"
                        value={password}
                        placeholder="password"
                        onChange={value => _.isString(value) && setPassword(value)}
                        onClear={() => setPassword('')}
                    />
                    <Button size="small" onClick={handleLogin}>
                        Login
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Login;
