import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { initStore } from 'stores/store';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
const store = initStore();
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
