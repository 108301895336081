import React from 'react';

/** Backend */
import Backend from '../views/backend/Backend';
import CreateNewCategory from '../views/backend/CreateNewCategory';
import CreateNewPost from '../views/backend/CreateNewPost';
import CreateNewTag from '../views/backend/CreateNewTag';
import Dashboard from '../views/backend/Dashboard';
import ListCategory from '../views/backend/ListCategory';
import ListPost from '../views/backend/ListPost';
import ListTag from '../views/backend/ListTag';
import Docs from '../views/backend/components/Docs';
import Login from '../views/backend/components/Login';
import Settings from '../views/backend/components/Settings'; 

export type RouterDataType = {
    path: string;
    element?: Element | React.ReactElement;
    children?: RouterDataType[];
};

const mainRouter: RouterDataType = {
    path: '/',
    children: [
        {
            path: '*',
            element: <div>Error</div>
        }, 
        {
            path: 'admin-login',
            element: <Login />
        },
        {
            path: '/',
            element: <Backend />,
            children: [
                {
                    path: 'dashboard',
                    element: <Dashboard />
                },
                {
                    path: 'post',
                    children: [
                        {
                            path: 'create-new',
                            element: <CreateNewPost />
                        },
                        {
                            path: 'list',
                            element: <ListPost />
                        }
                    ]
                },
                {
                    path: 'category',
                    children: [
                        {
                            path: 'create-new',
                            element: <CreateNewCategory />
                        },
                        {
                            path: 'list',
                            element: <ListCategory />
                        }
                    ]
                },
                {
                    path: 'tag',
                    children: [
                        {
                            path: 'create-new',
                            element: <CreateNewTag />
                        },
                        {
                            path: 'list',
                            element: <ListTag />
                        }
                    ]
                },
                {
                    path: 'settings',
                    element: <Settings />
                },
                {
                    path: 'docs',
                    element: <Docs />
                }
            ]
        }
    ]
};

export default mainRouter;
