import cx from 'classnames';
import _ from 'lodash';
import IconChevronDown from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/ChevronDown';
import IconChevronLeft from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/ChevronLeft';
import Text from 'qnb-ui/src/components/Text';
import __ from 'qnb-ui/src/helpers/i18n';

import NavItem from '../NavItem';
import { MenuTypeProps, SidebarProps } from '../Sidebar';
import styles from './NavCollapse.module.scss';

export type NavCollapse = SidebarProps & {
    collapse?: MenuTypeProps;
    currentCollapse?: string;
    changeCollapsed?: (key: string) => void;
};

function NavCollapse(props: NavCollapse) {
    const { collapse, level = 1, changeCollapsed, ...rest } = props;
    const open = collapse?.id && rest.currentCollapse === collapse.id;

    const handleClick = () => {
        collapse?.id && _.isFunction(changeCollapsed) && changeCollapsed(collapse.id);
    };

    const hasChildActived = _.includes(rest?.currentPageActived, collapse?.id);

    return (
        <div
            className={cx(
                styles.root,
                { ' has__ChildActived': hasChildActived },
                rest?.sidebarOpen ? 'sidebar-expanded' : 'sidebar-collapsed'
            )}
        >
            <div className={cx(styles.collapseHed, { opened: open })} onClick={handleClick}>
                {collapse?.icon && <span className={styles.icon}>{collapse?.icon}</span>}
                <Text className={styles.title}>{__('%1', collapse?.title)}</Text>
                <span className={styles.iconChevron}>
                    {open ? <IconChevronDown size="small" /> : <IconChevronLeft size="small" />}
                </span>
            </div>
            <div
                className={cx(styles.collapseBox, { closed: !open })}
                style={{
                    height: !open && rest?.sidebarOpen ? 0 : _.size(collapse?.children) * 35
                }}
            >
                <div className={styles.collapseContent}>
                    {_.map(collapse?.children, item => (
                        <NavItem key={item.id} item={item} level={level + 1} {...rest} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default NavCollapse;
