import cx from 'classnames';
import _ from 'lodash';
import Text from 'qnb-ui/src/components/Text';
import __ from 'qnb-ui/src/helpers/i18n';
import { Link } from 'react-router-dom';

import { MenuTypeProps, SidebarProps } from '../Sidebar';
import styles from './NavItem.module.scss';

export type NavItemProps = SidebarProps & {
    item: MenuTypeProps & { target?: '_self' | '_blank' };
};

function NavItem(props: NavItemProps) {
    const { item, level = 1, sidebarOpen, currentPageActived, pageChange } = props;

    let itemTarget = '_self';
    if (item.target) itemTarget = '_blank';

    const handleChangePage = () => {
        item.url && _.isFunction(pageChange) && pageChange(item.url);
    };
    return (
        <div
            className={cx(
                styles.root,
                {
                    ' side-collapsed': !sidebarOpen,
                    actived: currentPageActived === item.url
                },
                `level-${level}`
            )}
        >
            <Link to={item.url || ''} target={itemTarget} className={styles.link} onClick={handleChangePage}>
                {item?.icon && (
                    <>
                        {item?.icon === 'list-style' ? (
                            <span className={styles.iconListStyle} />
                        ) : (
                            <span className={styles.icon}>{item?.icon}</span>
                        )}
                    </>
                )}
                <Text className={styles.title}>{__('%1', item.title)}</Text>
            </Link>
        </div>
    );
}

export default NavItem;
