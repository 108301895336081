import cx from 'classnames';
import Heading from 'qnb-ui/src/components/Heading';
import __ from 'qnb-ui/src/helpers/i18n';

import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';
import { MenuTypeProps, SidebarProps } from '../Sidebar';
import styles from './NavGroup.module.scss';

export type NavGroupprops = SidebarProps & {
    group?: MenuTypeProps;
    currentCollapse?: string;
    changeCollapsed?: (key: string) => void;
};

function NavGroup(props: NavGroupprops) {
    const { group, ...rest } = props;

    const items = group?.children?.map(menu => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} collapse={menu} level={1} {...rest} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} {...rest} />;
            default:
                return <p>{__('Error')}</p>;
        }
    });

    return (
        <div className={cx(styles.root, { 'side-collapsed': !props?.sidebarOpen })}>
            {!!group?.title && (
                <Heading as="h6" className={styles.groupHeading}>
                    {__('%1', group.title)}
                </Heading>
            )}
            {items}
        </div>
    );
}

export default NavGroup;
